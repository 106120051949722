import { contactEmailEncode, schoolEmailEncode, helpEmailEncode } from './encoded.js';
export default function hiddenEmailHandler() {
    const emails = document.querySelectorAll('.hidden-email');
    emails.forEach(node => {
    node.addEventListener('mouseenter', () => {
    const { email } = node.dataset;
    if(email === "contact") {
      node.setAttribute('href', `mailto:${contactEmailEncode}`);
      node.textContent = contactEmailEncode;
    }
    else if (email === "school") {
      node.setAttribute('href', `mailto:${schoolEmailEncode}`);
      node.textContent = schoolEmailEncode;
    }
    else if (email === "help") {
      node.setAttribute('href', `mailto:${helpEmailEncode}`);
      node.textContent = helpEmailEncode;
    }
  })
})
}