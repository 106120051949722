/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// any CSS you import will output into a single css file (app.css in this case)
import "./css/style.css";
import "./css/links.css";
import "./css/main.css";

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from "jquery";
import hiddenEmailHandler from "./js/shared/hiddenEmailHandler";
if (window.location.pathname === "/") {
  import("./js/shared/advertisingHandle").then(({ default: advertisingHandle }) => {
    advertisingHandle();
  });
}
hiddenEmailHandler();
const {
  name,
  os: { family },
} = platform;
if ((name === "Safari" && family === "Windows") || name === "IE")
  alert(
    "Twoja przeglądarka nie jest już aktualizowana. Aby zapewnić sobie jak najlepsze wrażenia z korzystania z naszej strony, użyj innej przeglądarki (Chrome, Opera, Firefox...)"
  );
